import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import HomePage from './screens/HomePage';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import StoryPage from './screens/StoryPage';
import SkinPage from './screens/SkinPage';
import HairPage from './screens/HairPage';
import ProductPage from './screens/ProductPage';
import FAQPage from './screens/FaqPage';
import { AppProvider, useAppContext } from './screens/AppContext'; // Import context
import { BuyNowModal } from './components/Modal';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]);

  return null;
};

function App() {


  return (
    <AppProvider> {/* Wrap your entire App with the AppProvider */}
      <Router>
        <ResponsiveAppBar />
        <ScrollToTop /> {/* Scrolls to the top on route change */}
        <Routes>
          {/* Define your routes here */}
          <Route path="/" element={<HomePage />} />
          <Route path="/story" element={<StoryPage />} />
          <Route path="/skin" element={<SkinPage />} />
          <Route path="/hair" element={<HairPage />} />
          <Route path="/faq" element={<FAQPage />} />
          {/* Match all product-related paths */}
          <Route path="/product/*" element={<ProductPage />} />

          {/* Catch-all route for invalid paths */}
          <Route path="*" element={<HomePage />} />
        </Routes>

        {/* BuyNowModal controlled via context */}
        <BuyNowModal />
      </Router>
    </AppProvider>
  );
}

export default App;
