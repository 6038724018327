import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ReactComponent as Icon1 } from '../../assets/Icon_.svg'; // Replace with actual SVG paths
import { ReactComponent as Icon2 } from '../../assets/Icon_-2.svg';
import { ReactComponent as Icon3 } from '../../assets/Icon_-1.svg';
import productImage1 from '../../assets/OurProductsAre_01.webp'; // Add multiple images
import productImage2 from '../../assets/OurProductsAre_02.webp';
import productImage3 from '../../assets/OurProductsAre_03.webp';

export const Page7 = () => {
    const cardsData = [
        {
            title: 'Formulated With A Uniquely Patented Key Ingredient After 3 Years Of Research',
            description: 'Experience the power of our uniquely patented key ingredient, developed after three years of dedicated research for unparalleled skincare results.',
            icon: Icon1
        },
        {
            title: 'Extracted with no damage to important components',
            description: 'Our ingredients are carefully extracted with methods that preserve all vital components, ensuring maximum efficacy and skin health.',
            icon: Icon2
        },
        {
            title: 'Ingredients to improve skin’s health from within',
            description: 'Formulated with ingredients that enhance your skin’s health from within, our products provide deep nourishment and lasting benefits.',
            icon: Icon3
        },
    ];

    const images = [productImage1, productImage2, productImage3]; // Array of images to cycle through
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [images.length]);

    return (
        <Container
            maxWidth={false}
            id="product-features"
            sx={{
                position: 'relative',
                width: '100%',
                height: { xs: '350vh', md: '270vh' },
                backgroundColor: '#fefaf4',
                overflowX: 'clip',
            }}
        >
            <Grid container>
                {/* Left Cards */}
                <Grid item md={6} sx={{ paddingTop: '40px', paddingRight: '20px', paddingLeft: '20px' }}>
                    <Box
                        style={{
                            position: 'sticky',
                            top: '30px', // Keeps the title sticky at the top
                            right: 0,
                            maxWidth: '100%',
                            borderRadius: '20px',
                            objectFit: 'cover',
                            height: 'auto'
                        }}
                        sx={{
                            top: { xs: '10px', md: '50px' },
                            left: { xs: '20px', md: '5%' },
                            paddingRight: { xs: '20px', md: '50px' },
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'ClaireMurphy',
                                fontSize: { xs: '24px', md: '40px' }, // Adjusted for mobile
                                fontWeight: 400,
                                lineHeight: { xs: '30px', md: '46px' },
                                textAlign: 'left',
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '40px',
                                position: 'sticky',
                                top: '30px',
                                zIndex: 20,
                                paddingTop: '10px',
                            }}
                        >
                            OUR PRODUCTS ARE
                        </Typography>
                    </Box>

                    {cardsData.map((card, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                padding: '30px',
                                height: '80vh',
                                borderRadius: '20px',
                                backgroundColor: '#fff',
                                border: '1px solid rgba(221, 221, 221, 1)',
                                marginBottom: '30px',
                                position: 'sticky',
                                top: `${100}px`,
                                zIndex: 10 + index,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center', // Center the content within the card
                                textAlign: 'center',
                            }}
                        >
                            <card.icon style={{ marginBottom: '20px' }} />
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: { xs: '36px', md: '60px' }, // Adjusted for mobile
                                    fontWeight: 300,
                                    lineHeight: { xs: '45px', md: '75px' }, // Adjusted for mobile
                                    letterSpacing: '-0.03em',
                                    textAlign: 'left',
                                    color: 'rgba(61, 61, 61, 1)',
                                    marginBottom: '20px',
                                }}
                            >
                                {card.title}
                            </Typography>

                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand',
                                    fontSize: { xs: '14px', md: '18px' }, // Adjusted for mobile
                                    fontWeight: 400,
                                    textAlign: 'left',
                                    lineHeight: { xs: '20px', md: '26.1px' }, // Adjusted for mobile
                                    color: 'rgba(61, 61, 61, 1)',
                                }}
                            >
                                {card.description}
                            </Typography>
                        </Box>
                    ))}
                </Grid>

                {/* Right Image */}
                <Grid item md={6} sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <img
                        src={images[currentImageIndex]} // Display current image
                        alt="Product"
                        style={{
                            position: 'sticky',
                            top: '30px',
                            right: 0,
                            maxWidth: '100%',
                            borderRadius: '20px',
                            objectFit: 'cover',
                            height: 'auto',
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};
