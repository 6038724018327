import React from 'react';
import { Box, Container, Grid, Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import Ampuole from '../../assets/Product_Ampuole.webp';
import Cleanser from '../../assets/Product_Cleanser.webp';
import Sunscreen from '../../assets/Product_Sunscreen.webp';
import Toner from '../../assets/Product_Toner.webp';
import VeganIcon from '../../assets/vegan.png'; // Replace with actual image paths
import PkIcon from '../../assets/PkIcon.png';
import EWGIcon from '../../assets/EWGicon.png';
import VeganCertIcon from '../../assets/Veganicon.png';
import ExcellentIcon from '../../assets/Excellenticon.png';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

export const Page8: React.FC = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const { openModal } = useAppContext();
    return (
        <Container maxWidth={false} sx={{ paddingTop: '50px', paddingBottom: '50px', backgroundColor: '#fff' }}>
            <Grid container spacing={5} justifyContent="center" alignItems="center" sx={{ marginBottom: '100px' }}>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={VeganIcon}
                        alt={`VeganIcon`}
                        sx={{
                            width: 'auto',
                            height: '70px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={PkIcon}
                        alt={`PkIcon`}
                        sx={{
                            width: 'auto',
                            height: '70px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={EWGIcon}
                        alt={`EWGIcon`}
                        sx={{
                            width: 'auto',
                            height: '100px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={VeganCertIcon}
                        alt={`VeganCertIcon`}
                        sx={{
                            width: 'auto',
                            height: '100px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src={ExcellentIcon}
                        alt={`ExcellentIcon`}
                        sx={{
                            width: 'auto',
                            height: '100px', // Adjust the height to your preference
                            display: 'block',
                            maxWidth: '100%', // Ensure the image doesn't overflow its container
                        }}
                    />
                </Grid>
            </Grid>
            <Typography
                sx={{
                    fontFamily: 'ClaireMurphy', // Apply your custom font
                    fontSize: {
                        xs: '30px',
                        sm: '40px',
                        md: '50px',
                    },
                    fontWeight: 400,
                    textAlign: 'center',
                    color: 'rgba(61, 61, 61, 1)',
                    marginBottom: '30px',
                }}
            >
                RECOMMENDED PRODUCTS
            </Typography>
            <Grid container spacing={4} justifyContent="center" alignItems="stretch">
                {[
                    {
                        id: 1,
                        title: 'LBB 4-In-1 Micro Foam Cleanser',
                        volume: '100ml',
                        price: 'RM 218',
                        image: Cleanser,
                        path: '/product/cleanser', // Add path to the product details
                    },
                    {
                        id: 2,
                        title: 'LBB Moisturizing Prime Toner',
                        volume: '100ml',
                        price: 'RM 198',
                        image: Toner,
                        path: '/product/toner', // Add path to the product details
                    },
                    {
                        id: 3,
                        title: 'LBB Intensive Soothing Ampoule',
                        volume: '60ml',
                        price: 'RM 338',
                        image: Ampuole,
                        path: '/product/ampoule', // Add path to the product details
                    },
                    {
                        id: 4,
                        title: 'LBB All Day Air-Fit Sunscreen',
                        volume: '50ml',
                        price: 'RM 198',
                        image: Sunscreen,
                        path: '/product/sunscreen', // Add path to the product details
                    },
                ].map((product) => (
                    <Grid item xs={12} sm={6} md={3} key={product.id} display="flex">
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '100%',
                                borderRadius: '20px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                },
                            }}
                            onClick={() => navigate(product.path)} // Handle the click event to navigate
                        >
                            <Box sx={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                <CardMedia
                                    component="img"
                                    image={product.image}
                                    alt={product.title}
                                    sx={{
                                        objectFit: 'cover',
                                        borderRadius: '36px'
                                    }}
                                />
                            </Box>
                            <CardContent>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '30px',
                                        fontWeight: 500,
                                        lineHeight: '36px',
                                        letterSpacing: '-0.02em',
                                        textAlign: 'left',
                                        color: 'rgba(61, 61, 61, 1)',
                                        mb: 1,
                                    }}
                                >
                                    {product.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '26.1px',
                                        textAlign: 'left',
                                        color: 'rgba(136, 136, 136, 1)',
                                        mb: 1,
                                    }}
                                >
                                    {product.volume}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        lineHeight: '25px',
                                        letterSpacing: '0.1em',
                                        textAlign: 'left',
                                        color: 'rgba(61, 61, 61, 1)',
                                        mb: 2,
                                    }}
                                >
                                    {product.price}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderColor: 'rgba(0, 48, 135, 1)',
                                        color: 'rgba(0, 48, 135, 1)',
                                        borderRadius: '50px',
                                        textTransform: 'uppercase',
                                        fontWeight: 600,
                                        width: '100%',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 48, 135, 1)',
                                            zIndex: 0,
                                            transform: 'translateX(-100%)',
                                            transition: 'transform 0.4s ease',
                                        },
                                        '&:hover::before': {
                                            transform: 'translateX(0)',
                                        },
                                        '&:hover': {
                                            borderColor: '#003365',
                                            color: '#fff',
                                        },
                                        '& .button-text': {
                                            position: 'relative',
                                            zIndex: 1,
                                        },
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Stop event propagation
                                        openModal();
                                    }}
                                >
                                    <span className="button-text">Buy Now</span>
                                </Button>


                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};
