import React from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import productImage from '../../assets/Product_Tonic.webp'; // Replace with the actual image path
import largeProductImage from '../../assets/Hero_Tonic.webp'; // Replace with the actual image path
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

export const Page3 = () => {
    const navigate = useNavigate();
    const { openModal } = useAppContext();
    return (
        <Container maxWidth={false} sx={{ padding: '40px 20px', backgroundColor: '#fff', }}>
            <Grid container spacing={4} alignItems="flex-start" sx={{ minHeight: '100vh' }} >
                {/* Left Product Card */}
                <Grid item xs={12} md={8}
                >
                    <Box
                        sx={{
                            borderRadius: '36px',
                            backgroundImage: `url(${largeProductImage})`,
                            backgroundSize: 'cover',

                            backgroundRepeat: 'no-repeat',
                            minHeight: '100vh',
                            padding: {
                                md: '50px',
                                xs: '20px'
                            },
                            paddingTop: {
                                md: '100px',
                                xs: '20px'
                            },
                            position: 'sticky', // Make it sticky
                            top: '0px', // Adjust as needed
                            height: '100%', // Ensure the grid takes full height
                        }}
                    >
                        <Typography
                            sx={{
                                position: 'sticky',
                                top: '50px',
                                fontFamily: 'Quicksand',
                                fontSize: '16px',
                                fontWeight: 600,
                                color: 'rgba(255, 255, 255, 0.9)',
                                textAlign: 'right',
                                zIndex: 2, // Ensure it's above the image
                            }}
                        >
                            Scalp-Loving,<br /> Hair-Fall Reducing,<br />
                            Rejuvenating Shampoo.
                        </Typography>
                        <Typography
                            sx={{
                                position: 'sticky',
                                top: '500px',
                                fontFamily: 'Quicksand',
                                fontSize: '14px',
                                fontWeight: 600,
                                color: 'rgba(255, 255, 255, 0.9)',
                                textAlign: 'left',
                                zIndex: 2, // Ensure it's above the image
                            }}
                        >
                            Black Grain Complex,<br />
                            8 Types Of Hyaluronic Acid,<br />
                            And Copper Peptides.
                        </Typography>

                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{
                    position: 'sticky', // Make it sticky
                    top: '0px', // Adjust as needed
                }}>
                    <Box
                        onClick={() => navigate('/product/tonic')}
                        sx={{

                            position: 'sticky', // Make it sticky
                            top: '20px', // Adjust as needed
                            borderRadius: '36px',
                            border: '1px solid rgba(221, 221, 221, 1)',
                            overflow: 'hidden',
                            padding: '20px',
                            textAlign: 'center',
                            backgroundColor: '#fff',
                        }}>
                        <Box
                            component="img"
                            src={productImage}
                            alt="Revital Elixir Shampoo"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '24px',
                                objectFit: 'cover',
                                marginBottom: '20px',
                            }}
                        />
                        <Typography
                            variant='h4'
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '18px',
                                fontWeight: 600,
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '10px',
                                textAlign: 'left'
                            }}
                        >
                            LBB Miracle Hair Tonic
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '13px',
                                fontWeight: 400,
                                color: 'rgba(136, 136, 136, 1)',
                                marginBottom: '20px',
                                textAlign: 'left'
                            }}
                        >
                            100ml
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Quicksand',
                                fontSize: '16px',
                                fontWeight: 600,
                                color: 'rgba(61, 61, 61, 1)',
                                marginBottom: '20px',
                                textAlign: 'left'
                            }}
                        >
                            RM 289
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                borderColor: 'rgba(0, 48, 135, 1)',
                                color: 'rgba(0, 48, 135, 1)',
                                borderRadius: '50px',
                                textTransform: 'uppercase',
                                fontWeight: 600,
                                width: '100%',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 48, 135, 1)',
                                    zIndex: 0,
                                    transform: 'translateX(-100%)',
                                    transition: 'transform 0.4s ease',
                                },
                                '&:hover::before': {
                                    transform: 'translateX(0)',
                                },
                                '&:hover': {
                                    borderColor: '#003365',
                                    color: '#fff',
                                },
                                '& .button-text': {
                                    position: 'relative',
                                    zIndex: 1,
                                },
                            }}
                            onClick={(event) => {
                                event.stopPropagation(); // Stop event propagation
                                openModal();
                            }}
                        >
                            <span className="button-text">Buy Now</span>
                        </Button>

                    </Box>
                </Grid>

                {/* Right Large Product Image */}

            </Grid>
        </Container>
    );
};
