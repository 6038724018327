import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define a context interface
interface AppContextProps {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
}

// Create the context
const AppContext = createContext<AppContextProps | undefined>(undefined);

// Create a provider component
export const AppProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Functions to open and close the modal
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <AppContext.Provider value={{ isModalOpen, openModal, closeModal }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to access modal context
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
