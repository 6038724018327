import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import arrow from '../assets/ArrowLarge_White.svg';
import logo from '../assets/logo_white.svg';
import { ReactComponent as SocialIgWhite } from '../assets/Social_IG_White.svg';
import { ReactComponent as SocialFacebookWhite } from '../assets/Social_Facebook_White.svg';
import { useAppContext } from '../screens/AppContext';

export const Footer: React.FC = () => {
    const { openModal } = useAppContext();
    return (
        <Box sx={{ backgroundColor: '#003087', padding: '50px 0' }}>
            <Container maxWidth={false}>
                <Grid container spacing={4} sx={{ height: '100%' }}>
                    {/* Left Column */}
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box sx={{ padding: '20px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', height: '100%' }}>
                            <Box sx={{ marginBottom: '20px' }}>
                                <img src={logo} alt="LBB Logo" style={{ width: '100px' }} />
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '22.5px',
                                    textAlign: 'left',
                                    marginBottom: '20px',
                                    color: 'rgba(117, 150, 203, 1)',
                                }}
                            >
                                LBB Skincare is proudly introduced into Malaysia by{' '}
                                <Box component="span" sx={{ fontWeight: 700 }}>
                                    Lavisha
                                </Box>
                                , a wellness brand. With over 20 years of expertise, we offer premium yet affordable skincare, stemming from the finest aromatherapy and beauty treatments.
                            </Typography>
                            <Grid container spacing={2} sx={{ marginTop: '50px' }}>
                                <Grid item xs={6}>
                                    <Link
                                        href="/story"
                                        color="inherit"
                                        underline="hover"
                                        sx={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '22.5px',
                                            textAlign: 'left',
                                            color: '#fff',
                                        }}
                                    >
                                        About
                                    </Link>
                                    <Link
                                        href="/skin"
                                        color="inherit"
                                        underline="hover"
                                        sx={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '22.5px',
                                            textAlign: 'left',
                                            color: '#fff',
                                        }}
                                    >
                                        Skin
                                    </Link>
                                    <Link
                                        href="/hair"
                                        color="inherit"
                                        underline="hover"
                                        sx={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '22.5px',
                                            textAlign: 'left',
                                            color: '#fff',
                                        }}
                                    >
                                        Hair
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link
                                        href="https://lavishamsia.com/"
                                        color="inherit"
                                        underline="hover"
                                        sx={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '22.5px',
                                            textAlign: 'left',
                                            color: '#fff',
                                        }}
                                    >
                                        Lavisha Main Website
                                    </Link>
                                    <Link
                                        href="https://member.lbbmalaysia.com/#/login"
                                        color="inherit"
                                        underline="hover"
                                        sx={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '22.5px',
                                            textAlign: 'left',
                                            color: '#fff',
                                        }}
                                    >
                                        Member Login
                                    </Link>
                                    <Link
                                        href="/faq"
                                        color="inherit"
                                        underline="hover"
                                        sx={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '22.5px',
                                            textAlign: 'left',
                                            color: '#fff',
                                        }}
                                    >
                                        FAQ
                                    </Link>
                                </Grid>
                            </Grid>

                            {/* <Link href="#" color="inherit" underline="hover" sx={{ fontFamily: 'Quicksand, sans-serif', marginTop: '20px', fontSize: '18px', display: 'block', color: 'rgba(117, 150, 203, 1)' }}>Privacy Policy</Link> */}
                        </Box>
                    </Grid>

                    {/* Center Column */}
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box sx={{ padding: '30px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        lineHeight: '22.5px',
                                        color: 'rgba(255, 255, 255, 1)',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Address
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        color: 'rgba(117, 150, 203, 1)',
                                        marginBottom: '20px',
                                    }}
                                >
                                    08-12 Millerz Square, No 357, Jalan Old Klang Road, 58000 Kuala Lumpur, Malaysia.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        lineHeight: '26px',
                                        color: 'rgba(255, 255, 255, 1)',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Telephone
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        color: 'rgba(117, 150, 203, 1)',
                                        marginBottom: '20px',
                                    }}
                                >
                                    +603-8684 2416 <br />
                                    +6012-512 4162
                                </Typography>
                            </Box>

                            {/* <Link href="#" color="inherit" underline="hover" sx={{ color: 'rgba(117, 150, 203, 1)' }}>Terms Of Use</Link> */}
                        </Box>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box sx={{ padding: '30px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        lineHeight: '25px',
                                        textAlign: 'left',
                                        color: 'rgba(255, 255, 255, 1)',
                                        letterSpacing: '0.1em',
                                        marginBottom: '10px',
                                    }}
                                >
                                    JOIN THE LAVISHA FAMILY
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Quicksand, sans-serif',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        textAlign: 'left',
                                        color: 'rgba(117, 150, 203, 1)',
                                        marginBottom: '20px',
                                    }}
                                >
                                    Become a part of the Lavisha family and unlock exclusive benefits!
                                </Typography>
                                <Box
                                    component={'a'}
                                    onClick={() => openModal()}
                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px', cursor: 'pointer' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Quicksand, sans-serif',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            color: '#fff',
                                            marginRight: '10px',
                                        }}
                                    >
                                        JOIN NOW
                                    </Typography>
                                    <Box
                                        component="img"
                                        src={arrow}
                                        alt="Explore"
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </Box>
                                <Box sx={{ height: '1px', backgroundColor: '#fff', marginBottom: '20px' }} />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <IconButton href="https://www.instagram.com/lavisha.malaysia" sx={{ color: '#fff', border: '1px solid #fff', borderRadius: '10px' }}>

                                    <SocialIgWhite />
                                </IconButton>
                                <IconButton href="https://www.facebook.com/lbbmalaysia/" sx={{ color: '#fff', border: '1px solid #fff', borderRadius: '10px' }}>
                                    <SocialFacebookWhite />
                                </IconButton>
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Quicksand, sans-serif',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    textAlign: 'left',
                                    color: 'rgba(117, 150, 203, 1)',
                                    marginTop: '20px',
                                }}
                            >
                                @2024 LBB Malaysia Sdn. Bhd. (1509153U)
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    );
};
